<header class="header">
	<nav class="navbar navbar-expand-lg fixed-top py-3 active">
		<div class="container"><a href="https://nyaradzo.co.zw" target="_blank" class="navbar-brand">
				<img  src="./assets/media/logos/logo-9.png">
			</a>
			<button type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
				aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
				class="navbar-toggler navbar-toggler-right"><i class="fa fa-bars"></i></button>

			<div id="navbarSupportedContent" class="collapse navbar-collapse">
				<ul class="navbar-nav ml-auto">
					<!-- <li class="nav-item"><a href="#" class="nav-link text-uppercase font-weight-bold">Portfolio</a></li> -->
					<li class="nav-item lett-spacing">
						<a mat-button href="https://nyaradzo.co.zw/about" target="_self">ABOUT US</a>
					</li>
					<li class="nav-item lett-spacing">
						<a mat-button href="https://nyaradzo.co.zw/life_assurance" target="_self">LIFE INSURANCE</a>
					</li>
					<li class="nav-item lett-spacing">
						<a mat-button href="https://nyaradzo.co.zw/funeral-services/" target="_self">FUNERAL SERVICES</a>
					</li>
					<li class="nav-item lett-spacing">
						<a mat-button href="https://nyaradzo.co.zw/insurance/" target="_self">INSURANCE</a>
					</li>&nbsp;

					<li class="nav-item"><button type="button" (click)="navigate('/auth/login')"
							class="btn btn-primary">LOGIN</button></li>
				</ul>
			</div>
		</div>
	</nav>
</header>
