<app-layout>
  <mat-card>

		<h2 class="text-center">CHOOSE COUNTRY OF RESIDENCE</h2>
		<div class="row">
			<div class="col-md-4">
				<button type="button" (click)="selectedCountry(baseUrl + '/country', 187)"  class="btn btn-light btn-lg btn-block"><img width="30%" src="./assets/media/flags/260-united-kingdom.svg"><br> UNITED KINGDOM</button>
			</div>
			<div class="col-md-4">
				<button type="button" (click)="selectedCountry(baseUrl + '/country', 23)"  class="btn btn-light btn-lg btn-block"><img width="30%" src="./assets/media/flags/126-botswana.svg"><br>BOTSWANA</button>
			</div>
			<div class="col-md-4">
				<button type="button" (click)="selectedCountry(baseUrl + '/country', 122)"  class="btn btn-light btn-lg btn-block"><img width="30%" src="./assets/media/flags/062-namibia.svg"><br>NAMIBIA</button>
			</div>
			</div>

			<div class="kt-login__divider">
				<div class="kt-divider">
					<span></span>
					<span>SELECT COUNTRY</span>
					<span></span>
				</div>
			</div>


			  <!-- <mat-form-field appearance="fill"  class="full-width">
				<mat-label>SEARCH COUNTRY</mat-label>
				<input type="text" matInput [formControl]="myControl" [matAutocomplete]="auto">
				<mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
				  <mat-option *ngFor="let option of filteredOptions | async" [value]="option" (click)="onChangeCountry(baseUrl + '/region/' + option.nlacoRegion + '/' + option.countryID)">
					{{option.countryName}}
				  </mat-option>
				</mat-autocomplete>
			  </mat-form-field> -->


</mat-card>
</app-layout>
