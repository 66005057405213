import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header-auth',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderAuthComponent implements OnInit {

  constructor( private router: Router) { }

  ngOnInit() {
  }

  navigate(link) {
		this.router.navigate([link]);
	}

}
