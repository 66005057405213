import { RegisterClientComponent } from './components/register-client/register-client.component';
import { ConfirmClientComponent } from './components/confirm-client/confirm-client.component';
import { LoginFormComponent } from './components/login-form/login-form.component';
import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

import { AuthPageComponent } from './containers';
import { SignFormComponent } from './components/sign-form/sign-form.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';

const routes: Routes = [
  {
    path: '',
    component: AuthPageComponent,
    children: [
      {
				path: '',
				redirectTo: 'login',
				pathMatch: 'full'
			},
      {
        path: 'login',
        component: LoginFormComponent
      },
      {
        path: 'confirm_client',
        component: ConfirmClientComponent
      },
      {
        path: 'register_client',
        component: RegisterClientComponent
      },
      {
        path: 'register',
        component: SignFormComponent
      },
      {
				path: 'forgot-password',
				component: ForgotPasswordComponent,
			},
			{
				path: 'reset-password/:resetToken',
				component: ResetPasswordComponent,
			}

    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})

export class AuthRoutingModule {
}
