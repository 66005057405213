<app-layout>
  <mat-toolbar class="page-header" role="heading">
    <h3>Dashboard</h3>
    <!-- <button mat-flat-button>Latest Reports</button> -->
  </mat-toolbar>
  <mat-card class="mat-card">
    <mat-card-content >
      <div class="row">
        <div class="col-md-3">
              <div style="margin-top: 10px;" class="button_cont" align="center" (click)="navigate('/policy/other')"><a class="rk-big-btn">
              <img src="./assets/media/dash-icons/icons8-family-48.png"><br>MY POLICY</a></div>
        </div>
        <div class="col-md-3">
          <div style="margin-top: 10px;" class="button_cont" align="center"><a class="rk-big-btn"
               (click)="navigate('/payments/history')">
              <img src="./assets/media/dash-icons/icons8-currency-exchange-48.png"><br>PAYMENT HISTORY</a>
          </div>
        </div>



        <div class="col-md-3">
          <div style="margin-top: 10px;" class="button_cont" align="center"><a class="rk-big-btn"
              (click)="navigate('/payments/new')">
              <img src="./assets/media/dash-icons/icons8-get-cash-48.png"><br>MAKE PAYMENT</a></div>
        </div>
        <div class="col-md-3">
          <div style="margin-top: 10px;" class="button_cont" align="center"><a class="rk-big-btn"
            (click)="navigate('/profile')">
              <img src="./assets/media/dash-icons/icons8-customer-48.png"><br>MY PROFILE</a>
          </div>
        </div>
      </div>
    </mat-card-content>
    </mat-card>

  <kt-get-quote [links]="links"></kt-get-quote>
  <app-footer></app-footer>
</app-layout>
