
		<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }" ></ngx-loading>
		<button routerLink="/auth/confirm_client" [disabled]="false"  class="btn btn-link"><i class="fa fa-arrow-left"></i></button>
		<div class="text-center">
			<h4>Enter policy details?</h4>
		</div>

    <ngb-alert *ngIf="messageAlert.viewMsg" [type]="messageAlert.type" [dismissible]="true">
      {{messageAlert.msg}}
    </ngb-alert>
		<form  class="kt-form" [formGroup]="registerForm" autocomplete="off">
			<div class="row">
				<div class="col-md-6">
					<div class="form-group">
						<mat-form-field class="full-width" appearance="fill">
							<mat-label>Policy Number</mat-label>
							<input matInput type="text" placeholder="Policy Number" formControlName="policyNumber" [readonly]="policyNumberReadonly"/>
							<mat-error *ngIf="isControlHasError('policyNumber','required')">
								<strong>Policy Number Required</strong>
							</mat-error>
						</mat-form-field>
					</div>
				</div>
				<div class="col-md-6">
					<div class="form-group">
						<mat-form-field class="full-width" appearance="fill">
							<mat-label>ID Number</mat-label>
							<input matInput type="text" placeholder="ID Number" placeholder="e.g 50111111X56" formControlName="idNumber" [readonly]="idNumberReadonly"/>
							<mat-error *ngIf="isControlHasError('idNumber','required')">
								<strong>ID Number Required</strong>
							</mat-error>
						</mat-form-field>
					</div>
				</div>
        <div class="col-md-6">

            <mat-checkbox class="example-margin" (change)="sipChange($event.checked)">Sahwira International Plan</mat-checkbox>

        </div>

			</div>
			<div *ngIf="isPolicyExist" class="row">
				<div class="col-md-6">
					<div class="form-group">
						<mat-form-field class="full-width" appearance="fill">
							<mat-label>Firstname</mat-label>
							<input matInput type="text" placeholder="Firstname" formControlName="firstName" readonly="true"/>
						</mat-form-field>
					</div>
				</div>
				<div class="col-md-6">
					<div class="form-group">
						<mat-form-field class="full-width" appearance="fill">
							<mat-label>Lastname</mat-label>
							<input matInput type="text" placeholder="Lastname" formControlName="lastName" readonly="true"/>
						</mat-form-field>
					</div>
				</div>

			</div>
			<div *ngIf="isPolicyExist" class="row">
				<div class="col-md-6">
					<div class="form-group">
						<mat-form-field class="full-width" appearance="fill">
							<mat-label>Email</mat-label>
							<input matInput type="email" placeholder="Email" formControlName="email" />
							<mat-error *ngIf="isControlHasError('email','required')">
								<strong>Email is required.</strong>
							</mat-error>
							<mat-error *ngIf="isControlHasError('email','email')">
								<strong>Email is not valid.</strong>
							</mat-error>
							<mat-error *ngIf="isControlHasError('email','minlength')">
								<strong>Email length is short.</strong>
							</mat-error>

						</mat-form-field>
					</div>
				</div>
				<div class="col-md-6">
					<div class="form-group">
						<mat-form-field class="full-width" appearance="fill">
							<mat-label>Mobile Number</mat-label>
							<input matInput type="text" placeholder="Mobile Number" formControlName="mobileNumber" required/>
							<mat-error *ngIf="isControlHasError('mobileNumber','required')">
								<strong>Mobile number is required</strong>
							</mat-error>
						</mat-form-field>
					</div>
				</div>

			</div>
			<div *ngIf="isPolicyExist" class="row">
				<div class="col-md-6">
					<div class="form-group">
						<mat-form-field class="full-width" appearance="fill">
							<mat-label>PASSWORD</mat-label>
							<input matInput type="password" placeholder="Password" formControlName="password" autocomplete="off"/>
              <mat-error *ngIf="isControlHasError('confirmPassword','required')">
								<strong>Confirm password is required</strong>
							</mat-error>
							<mat-error *ngIf="isControlHasError('confirmPassword','minlength')">
								<strong>Length should be greater than  6</strong>
							</mat-error>
							<mat-error *ngIf="registerForm.get('confirmPassword').errors && registerForm.get('confirmPassword').errors.ConfirmPassword">
                <strong>Passsword and Confirm Password didn't match.</strong>
              </mat-error>
						</mat-form-field>
					</div>
				</div>
				<div class="col-md-6">
					<div class="form-group">
						<mat-form-field class="full-width" appearance="fill">
							<mat-label>CONFIRM PASSWORD</mat-label>
							<input matInput type="password"  placeholder="Confirm Password" formControlName="confirmPassword" autocomplete="off"/>
							<mat-error *ngIf="isControlHasError('confirmPassword','required')">
								<strong>Confirm password is required</strong>
							</mat-error>
							<mat-error *ngIf="isControlHasError('confirmPassword','minlength')">
								<strong>Length should be greater than  6</strong>
							</mat-error>
							<mat-error *ngIf="registerForm.get('confirmPassword').errors && registerForm.get('confirmPassword').errors.ConfirmPassword">
								<strong>Passsword and Confirm Password didn't match.</strong>
							</mat-error>
						</mat-form-field>
					</div>
				</div>

			</div>

			<!--begin::Action-->
			<div class="action-btns">
				<button *ngIf="!isPolicyExist" (click)="submitForm(true)"  class="btn btn-primary btn-elevate action-btn" [ngClass]="{'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light': loading}">Submit</button>
				<button *ngIf="isPolicyExist" (click)="submitForm(false)" class="btn btn-primary btn-elevate action-btn" [ngClass]="{'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light': loading}">Create Account</button>
			</div>
			<!--end::Action-->
		</form>

