import { Country } from '@angular-material-extensions/select-country';
import { ChangeDetectorRef, Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { tap, finalize, takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/core/auth.service';
import { AuthStorage } from 'src/app/core/auth.storage';
import { CrudService } from 'src/app/core/crud.service';
import { Alert } from 'src/app/core/model/alert';
import { JwtTokenResponse } from 'src/app/core/model/token.response';
import { CountryISO, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input';

@Component({
  selector: 'app-zympay',
  templateUrl: './zympay.component.html',
  styleUrls: ['./zympay.component.scss']
})
export class ZympayComponent implements OnInit {
  @Input() type: string;
   pN: string;

   separateDialCode = false;
	SearchCountryField = SearchCountryField;
	CountryISO = CountryISO;
    PhoneNumberFormat = PhoneNumberFormat;
	preferredCountries: CountryISO[] = [ CountryISO.Zimbabwe,CountryISO.UnitedKingdom,CountryISO.UnitedStates,CountryISO.Australia,CountryISO.Austria,CountryISO.Belgium,CountryISO.Botswana,
	CountryISO.Bulgaria,CountryISO.Canada,CountryISO.Croatia,CountryISO.Cyprus,CountryISO.Denmark,CountryISO.Estonia,CountryISO.Finland,CountryISO.France,CountryISO.Germany,CountryISO.Hungary,
    CountryISO.India,CountryISO.Ireland,CountryISO.Italy,CountryISO.Latvia,CountryISO.Lithuania,CountryISO.Luxembourg,CountryISO.Malaysia,CountryISO.Malta,CountryISO.Namibia,CountryISO.Netherlands,
     CountryISO.NewZealand,CountryISO.Nigeria,CountryISO.Poland,CountryISO.Portugal,CountryISO.Romania,CountryISO.Slovakia,CountryISO.Slovenia,CountryISO.SouthAfrica,CountryISO.Spain,CountryISO.Swaziland,
	 CountryISO.Sweden,CountryISO.Switzerland,CountryISO.UnitedArabEmirates,CountryISO.Zambia ];

  // change the form name........
  registerForm: FormGroup;
	loading = false;
	errors: any = [];
	clientId: 86;
	productId: 'NYARADZOINS';
	reference = 'ZFuneral0001';
	isAgent = true;
	ngxLoading = false;
	viewMsg = false;
  exists = false;
  alert: Alert;
  countryInfo = {iso2: 'ZW', name: 'Zimbabwe', dialCode: '263'};
  paymentType = 'Payment';
  private unsubscribe: Subject<any>;
	customer: any;
	policyNumber: string;
	policyPremium: any;
	canMakePayment= false;
	subgroupCurrency = null;
	currencyCode: string;
  nyaradzoFranchise: string;



	/**
	 * Component constructor
	 *
	 * @param router: Router
	 * @param auth: AuthService
	 * @param store: Store<AppState>
	 * @param fb: FormBuilder
	 * @param cdr
	 */
	constructor(
		private router: Router,
		private auth: AuthService,
		private fb: FormBuilder,
		private cdr: ChangeDetectorRef,
		private service: CrudService,
    private authStorage: AuthStorage,
    private toastService: ToastrService
	) {
    this.unsubscribe = new Subject();
    this.pN = sessionStorage.getItem('pN');
    this.policyNumber = sessionStorage.getItem('pN');
	}

	/*
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
    */

	/**
	 * On init
	 */
  ngOnInit() {
    this.getCustomerDetails();


	if(this.type !== undefined){
		if(this.type === 'recurring'){
			this.paymentType = 'RecurringPayment';
		}
		this.initRegisterForm(this.type);
	} else{
		this.initRegisterForm(null);
	}
	this.getPolicyPremium();

	}
	getCustomerDetails() {
		this.customer = this.authStorage.getUser();
	}

/*
    * On destroy
    */
   ngOnDestroy(): void {
		this.unsubscribe.next();
		this.unsubscribe.complete();
		this.loading = false;
	}
	/**
	 * Form initalization
	 * Default params, validators
	 */
	initRegisterForm(type: string) {
    this.customer = this.authStorage.getUser();


		this.registerForm = this.fb.group({
      id: [null],
			firstname: ['', [Validators.maxLength(25),
				Validators.minLength(2),
				Validators.pattern('^(?=.*[a-zA-Z])[a-zA-Z0-9]+$'),
				Validators.required]],
			surname: ['', [Validators.maxLength(25),
				Validators.minLength(2),
				Validators.pattern('^(?=.*[a-zA-Z])[a-zA-Z0-9]+$'),
				Validators.required]],

			mobile: ['',
			Validators.compose([
				Validators.required,
				Validators.minLength(5),
				Validators.maxLength(30)
			]),
		],
		telephone: ['',
		// Validators.compose([
		// 	Validators.required,
		// 	Validators.minLength(7),
		// 	Validators.maxLength(30)
		// 	])
			],
			idNumber: [''],
			address1: [''],
      address2: [''],
      city: [''],
			gender: ['OTHER'],
      title: [''],
      amount: ['', Validators.compose([
				Validators.required,
			]),],
      email: ['', Validators.compose([
        Validators.required,
        Validators.email
			]),],
      currencycode: [''],
      frequency: [type === 'once-off' ? 'None' : 'Monthly'],
      paymentmethod: [type=== 'once-off' ? 'CC': ''],
      isAgent: [true],
      productId: [this.productId],
      clientId: [this.clientId],
      reference: [''],//to put policynumber
      setuptype: ['Payment']

		},);
	}

	/**
	 * Form Submit
	 */
	submitForm() {
		const controls = this.registerForm.controls;
		if (this.registerForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			return;
		}
		this.loading = true;
        this.saveClientDetails();
	}


  mobileNumberChange(value) {
    console.log(value);
    this.countryInfo = value;
    this.cdr.markForCheck();
    this.cdr.detectChanges();

  }

  onCountrySelected(country: Country) {
    console.log(country)	;
  }




  getPolicyPremium() {
	  // console.log('getting policy details...');
    // console.log(this.pN);

    this.loading = true;
		this.service.getAny('/easipol/policy/premium/' + this.pN).subscribe(
			result => {
			//	console.log(result);

        this.loading = false;
        this.policyPremium = result;
        this.policyNumber = this.policyPremium!.PolicyNumber;
        // console.log(this.policyPremium);

        // console.log(this.policyNumber);

			 this.registerForm.get('firstname').setValue(this.policyPremium!.Name);
			 this.registerForm.get('surname').setValue(this.policyPremium!.Surname);
        this.registerForm.get('mobile').setValue(this.policyPremium!.Cell_Number);
        this.registerForm.get('amount').setValue(this.policyPremium!.PolicyPremium);
        this.registerForm.get('reference').setValue(this.policyPremium!.PolicyNumber);
        this.getSubGroupCurrency(result?.SubGroupID);
			 this.cdr.markForCheck();
		});
	}





	saveClientDetails() {
	     this.viewMsg = false;
       this.service.save(this.registerForm?.value, '/customer/save').subscribe(result => {
		   this.loading = false;
		   this.exists = result.duplicate;
		   if (result.duplicate) {

          this.toastService.error('Account already exists!', 'Account Exist');
          this.alert = {message: 'Account already exists!', type: 'danger'};
          this.viewMsg = true;
		   } else if (result) {
          this.toastService.success('Account created successfully!', 'Account Status');
          this.alert = {message: 'Account created successfully!', type: 'success'};
          this.toastService.info('Signing you in...', 'Account Created');
          this.viewMsg = true;
          this.authClient(result?.item.email, this.registerForm.get('password').value);
		   }
	   },
	   error => {
          this.loading = false;
          this.toastService.error('Error Encountered when creating your account!', 'danger');
          this.viewMsg = true;
	   },
	   () => {
          this.loading = false;
          this.cdr.markForCheck();
	      }
	   );
	}
	authClient(email: string, pwd: string) {
		this.ngxLoading = true;
		const authData = {userName: email, password: pwd};
		this.auth
		.login(authData.userName, authData.password)
		.pipe(
			tap((jwtTokenResponse: JwtTokenResponse) => {
				this.ngxLoading = false;
				if (jwtTokenResponse) {
					this.authStorage.saveToken( jwtTokenResponse.token);
					this.authStorage.saveUser(JSON.stringify(jwtTokenResponse.customer));
					this.router.navigate(['/dashboard']);
				} else {
          this.toastService.error('Error Encountered !', 'Error');
					this.viewMsg = true;
					this.router.navigateByUrl('/auth/login');
				}
			}),
			takeUntil(this.unsubscribe),
			finalize(() => {
				this.ngxLoading = false;
				// this.authNoticeService.setNotice(this.translate.instant('AUTH.VALIDATION.INVALID_LOGIN'), 'danger');
				this.cdr.markForCheck();
			})
		)
		.subscribe();
	}
	/**
	 * Checking control validation
	 *
	 * @param controlName: string => Equals to formControlName
	 * @param validationType: string => Equals to valitors name
	 */
	isControlHasError(controlName: string, validationType: string): boolean {
		const control = this.registerForm.controls[controlName];
		if (!control) {
			return false;
		}

		const result = control.hasError(validationType) && (control.dirty || control.touched);
		return result;
	}
  navigate(link) {
		this.router.navigate([link]);
  }
  getSubGroupCurrency(subgroupId){
		this.service.getAny('/currency/subgroup/get-by-subGroupId?subGroupId=' + subgroupId).subscribe(
			result =>{
				this.subgroupCurrency = result;
        this.currencyCode = this.subgroupCurrency?.currency?.code;
        // if (this.currencyCode == "GBP") {
        //   this.currencyCode = "GB";
        // }
        this.nyaradzoFranchise = this.subgroupCurrency?.nyaradzoFranchise;
        this.registerForm.get('currencycode').setValue(this.currencyCode);
      //  console.log(this.currencyCode);

			},
			error =>{
				console.log(error);
			},
			()=>{
				this.cdr.markForCheck();
			}
		)

	}

}
