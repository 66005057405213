import { AuthStorage } from './../../../core/auth.storage';
// Angular
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
// RxJS
import { Observable } from 'rxjs';



@Injectable()
export class AuthGuard implements CanActivate {
    constructor(private router: Router, private authStorage: AuthStorage) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
		if (this.authStorage.isLoggedIn()) {
			return true;
		} else {
			this.router.navigateByUrl('/auth/login');

			return false;
		}
    }
}
