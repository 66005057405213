<div class="container mt-5">
  <div class="row justify-content-center">
    <div class="col-md-6">
      <div class="text-center">
        <ng-container *ngIf="status === 'APPROVED'">
          <i class="fa fa-check-circle fa-5x text-success"></i>
          <h2 class="mt-3">Payment Successful</h2>
          <h3 class="mt-3">Your payment of {{ amount | currency:'USD':'symbol' }} has been processed successfully.</h3>
          <p>You have paid with VISA/MASTERCARD</p>
        </ng-container>
        <ng-container *ngIf="status === 'CANCELLED'">
          <i class="fa fa-times-circle fa-5x text-danger"></i>
          <h2 class="mt-3">Payment Canceled</h2>
          <h3 class="mt-3">Your payment of {{ amount | currency:'USD':'symbol' }} has been canceled.</h3>
        </ng-container>
        <ng-container *ngIf="status === 'TIMEOUT'">
          <i class="fa fa-clock-o fa-5x text-warning"></i>
          <h2 class="mt-3">Payment Timeout</h2>
          <h3 class="mt-3">Your payment of {{ amount | currency:'USD':'symbol' }} has timed out.</h3>
        </ng-container>
      </div>
    </div>
  </div>
</div>

