<div class="rk-space-items-between">
  <button routerLink="/auth/login" [disabled]="false" class="btn btn-link"><i class="fa fa-arrow-left"></i></button>
  <h3>Forgot password</h3>
</div>

<ngb-alert *ngIf="messageAlert.viewMsg" [type]="messageAlert.type" [dismissible]="true">
  {{messageAlert.msg}}
</ngb-alert>
<form class="form" [formGroup]="forgotPasswordForm" autocomplete="off">
  <mat-form-field class="full-width" appearance="fill">
    <mat-label>Email</mat-label>
    <input matInput type="email" placeholder="Email" formControlName="email" autocomplete="off" />
    <mat-error *ngIf="isControlHasError('email','required')">
      <strong>Email is required.</strong>
    </mat-error>
    <mat-error *ngIf="isControlHasError('email','email')">
      <strong>Email is not valid.</strong>
    </mat-error>
    <mat-error *ngIf="isControlHasError('email','minlength')">
      <strong>Email length is short.</strong>
    </mat-error>
  </mat-form-field>

  <!--begin::Action-->
  <div class="confirm-btns">
    <button (click)="submit()" [disabled]="loading" class="btn btn-primary btn-elevate  confirm-btn"> Submit <i
        *ngIf="loading" class="fa fa-spinner fa-spin"></i></button>
  </div>
  <!--end::Action-->
</form>
