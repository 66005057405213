<mat-card>
  <mat-card-content>


		<h3 class="text-center">GET QUOTE | DOWNLOAD FORM AND APPLY</h3>
		<div style="margin-top: 10px;" class="row">
			<div class="col-md-4">
				<div class="card mb-3 text-center">
					<h5 class="card-header">SAHWIRA INTERNATIONAL PLAN</h5>
					<!-- <ngb-carousel>
						<ng-template ngbSlide>


							<img  src="./assets/media/courasel/sip-e.jpg" alt="Random first slide">
						</ng-template>
					</ngb-carousel> -->
					<div><img class="img-style" src="./assets/media/courasel/Sip-min.jpg" alt="Random first slide"></div>

					<div class="card-body">
						<h5 class="card-title">About SIP</h5>
						<div style="height: 170px;">
						<p class="card-text">We understand that losing a loved one can be stressful mainly due to financial and logistical constraints.
							The Sahwira International Plan (SIP) is affordable, convenient and designed to give you peace of mind.
							It provides funeral cover to Zimbabweans resident in foreign countries who wish to be buried at home should they pass on.
						   </p>
					</div>
						<a mat-stroked-button color="primary" href="https://sip.nyaradzo.global/get-quote-apply/?quote_only" target="_blank">Get Quote Now</a>&nbsp;


					</div>
				</div>
			</div>
			<div class="col-md-4">
				<div class="card mb-3 text-center">
					<h5 class="card-header">SCORE PACK PLAN</h5>
					<!-- <ngb-carousel>
						<ng-template ngbSlide>
							<img  src="./assets/media/courasel/Scorepack-min.jpg" alt="Random first slide">
							<img  src="./assets/media/courasel/score-pack-e.jpg" alt="Random first slide">
						</ng-template>
					</ngb-carousel> -->
					<div><img class="img-style" src="./assets/media/courasel/Scorepack-soccer-3.jpg" alt="Random first slide"></div>
					<div class="card-body">
						<h5 class="card-title">About Scorepack</h5>
						<div style="height: 170px;">
						<p class="card-text">This plan provides funeral cover for the Principal Member as well as an unlimited number of dependents.
							It is entirely up to the policy holder to choose the number of dependents they want covered under this plan.
							 Its uniqueness is in the flexibility on the number of people that can be covered from a single person to as many people as one would want. </p>
					</div>
						<button mat-stroked-button color="primary" (click)="navigate(links.scorepack)" >Get Quote Now</button>&nbsp;
						<button mat-stroked-button color="warn" (click)="downLoadForm('scorepack')" >Download Form</button>
					</div>
				</div>
			</div>
			<div class="col-md-4">
				<div class="card mb-3 text-center">
					<h5 class="card-header">SIX PACK PLAN</h5>
					<!-- <ngb-carousel>
						<ng-template ngbSlide>
							<img src="./assets/media/courasel/SixPack-min.jpg" alt="Random first slide">
							<img src="./assets/media/courasel/six-pack-e.jpg" alt="Random first slide">
						</ng-template>
					</ngb-carousel> -->
					<div><img class="img-style" src="./assets/media/courasel/SixPack.jpg" alt="Random first slide"></div>
					<div class="card-body">
						<h5 class="card-title">About Sixpack</h5>
						<div style="height: 170px;">
						<p class="card-text">This funeral package is designed with the Zimbabwean traditional practices and culture in mind.
							 It is our Zimbabwean practice that we care for our extended families in good and bad times.
							  With the Six Pack Plan one can cover their spouse and children, parents and parent-in-laws under one policy transaction. </p>
					</div>
						<button mat-stroked-button color="primary" (click)="navigate(links.sixpack)" >Get Quote Now</button>&nbsp;
						<button mat-stroked-button color="warn" (click)="downLoadForm('sixpack')" >Download Form</button>
					</div>
				</div>
			</div>

		</div>

  </mat-card-content>
</mat-card>
