<div class="modal-header">
	<h4 class="modal-title">{{title}}</h4>
	<button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
	  <span aria-hidden="true">&times;</span>
	</button>
  </div>
  <div class="modal-body">
	<form class="kt-form" id="kt_form"  [formGroup]="form" >
		<div class="kt-wizard-v2__form">
			<div class="row">
				<div class="col-xl-6">
					<div class="form-group">
						<label>Firstname</label>
						<input type="text" class="form-control" formControlName="firstName" placeholder="Firstname">
						<span *ngIf="form.get('firstName').touched && form.get('firstName').errors?.required" class="help-block text-danger"><p>Firstname field is required.</p></span>
					</div>
				</div>
				<div class="col-xl-6">
					<div class="form-group">
						<label>Lastname</label>
						<input type="text" class="form-control" formControlName="lastName" placeholder="Lastname" >
						<span *ngIf="form.get('lastName').touched && form.get('lastName').errors?.required" class="help-block text-danger"><p>Lastname field is required.</p></span>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-xl-6">
					<div class="form-group">
						<label>Date Of Birth</label>
						<input type="date" class="form-control" formControlName="dateOfBirth" placeholder="Date Of Birth"  max="{{dateNow | date:'yyyy-MM-dd'}}" min="{{minDate | date:'yyyy-MM-dd'}}" (change)="dateChange($event.target.value)">
						<span *ngIf="form.get('dateOfBirth').touched && form.get('dateOfBirth').errors?.required" class="help-block text-danger"><p>Date of Birth field is required.</p></span>
					</div>
				</div>
				<div class="col-xl-6">
					<div class="form-group">
						<label>Age</label>
						<input type="number" class="form-control" formControlName="age" placeholder="Age" readonly="true">
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-xl-6">
					<div class="form-group">
						<label>Relationship</label>
						<select *ngIf="memberType === 'C'; else otherBlock"  class="form-control" formControlName="relationship" (change)="relationshipChange($event.target.value)">
							<option [ngValue]="null" selected="true">--Select Relationship--</option>
							<option value="SON">SON</option>
							<option value="DAUGHTER">DAUGHTER</option>
						</select>
						<ng-template #otherBlock>
							<select class="form-control" formControlName="relationship" (change)="relationshipChange($event.target.value)">
								<option [ngValue]="null" selected="true">--Select Relationship--</option>
								<option value="BROTHER">BROTHER</option>
								<option value="SISTER">SISTER</option>
								<option value="SON">SON</option>
								<option value="DAUGHTER">DAUGHTER</option>
								<option value="DAUGHTER-IN-LAW">DAUGHTER-IN-LAW</option>
								<option value="SON-IN-LAW">SON-IN-LAW</option>
								<option value="BROTHER-IN-LAW">BROTHER-IN-LAW</option>
								<option value="SISTER-IN-LAW">SISTER-IN-LAW</option>
								<option value="SECONDARY SPOUSE">SECONDARY SPOUSE</option>
								<option value="MOTHER">MOTHER</option>
								<option value="FATHER">FATHER</option>
								<option value="GRANDMOTHER">GRANDMOTHER</option>
								<option value="GRANDFATHER">GRANDFATHER</option>
								<option value="GRANDDAUGHTER">GRANDDAUGHTER</option>
								<option value="GRANDSON">GRANDSON</option>
								<option value="FATHER-IN-LAW">FATHER-IN-LAW</option>
								<option value="MOTHER-IN-LAW">MOTHER-IN-LAW</option>
								<option value="AUNT">AUNT</option>
								<option value="COUSIN">COUSIN</option>
								<option value="UNCLE">UNCLE</option>
								<option value="NEPHEW">NEPHEW</option>
								<option value="NIECE">NIECE</option>
								<option value="OTHER">OTHER</option>
							</select>
						</ng-template>

						<span *ngIf="form.get('relationship').touched && form.get('relationship').errors?.required" class="help-block text-danger"><p>Relationship field is required.</p></span>
					</div>
				</div>
				<div class="col-xl-6">
					<div class="form-group">
						<label>Gender</label>
						<select class="form-control" formControlName="gender">
							<option [ngValue]="null" selected="true">--Select Gender--</option>
							<option value="MALE">MALE</option>
							<option value="FEMALE">FEMALE</option>
						</select>
						<span *ngIf="form.get('gender').touched && form.get('gender').errors?.required" class="help-block text-danger"><p>Gender field is required.</p></span>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-xl-6">
					<div class="form-group">
						<label>ID Number</label>
						<input type="text" class="form-control" formControlName="idNumber" placeholder="ID Number"  (ngModelChange)="validateIdNumber($event)">
						<span *ngIf="form.get('idNumber').touched && form.get('idNumber').errors?.required" class="help-block text-danger"><p>ID Number field is required.</p></span>
            <span
                            *ngIf="!isExtendedIdValid && memberType === 'E'"
                            class="help-block text-danger">
                            <p>ID Number is not valid.</p>
                          </span>
					</div>
				</div>
				<div class="col-xl-6">
					<div class="form-group">
						<label>Passport Number</label>
						<input type="text" class="form-control" formControlName="passportNumber" placeholder="Passport Number">

					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-xl-6">
					<div class="form-group">
						<label>Mobile Number</label>
						<input type="text" class="form-control" formControlName="mobileNumber" placeholder="Mobile Number" >
					</div>
				</div>
				<div class="col-xl-6">
					<div class="form-group">
						<label>Email</label>
						<input type="text" class="form-control" formControlName="email" placeholder="Email">

					</div>
				</div>
			</div>



		</div>
	</form>
  </div>
  <div class="modal-footer">
	<button type="button" class="btn btn-primary" (click)="submitForm()">Save</button>
	<button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Close</button>
  </div>
