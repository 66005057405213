<app-layout>
  <mat-card>


		<div class="row">


					<div class="col-md-9">

						<form [formGroup]="form">
              <mat-horizontal-stepper linear labelPosition="bottom" #stepper>
              <mat-step formGroupName="mainMember" [stepControl]="mainMember">
                <ng-template matStepLabel>MAIN MEMBER</ng-template>
                <h5>{{quoteSummary.customer.firstName | uppercase}}
                  {{quoteSummary.customer.lastName | uppercase}}
                </h5>
                <div>
                  <div class="row">
                    <div class="col-xl-2">
                      <div class="form-group">
                        <label>Title</label>
                        <select class="form-control" formControlName="title">
                          <option [ngValue]="null" selected="true">Title</option>
                          <option value="MR">MR</option>
                          <option value="MRS">MRS</option>
                          <option value="MISS">MISS</option>
                          <option value="DR">DR</option>

                        </select>
                        <span
                          *ngIf="mainMember?.get('title')?.touched && mainMember?.get('title').errors?.required"
                          class="help-block text-danger">
                          <p>Title field is required.</p>
                        </span>

                      </div>
                    </div>
                    <div class="col-xl-4">
                      <div class="form-group">
                        <label>Firstname</label>
                        <input type="text" class="form-control" formControlName="firstName"
                          placeholder="Firstname">
                        <span
                          *ngIf="mainMember.get('firstName').touched && mainMember.get('firstName').errors?.required"
                          class="help-block text-danger">
                          <p>Firstname field is required.</p>
                        </span>
                      </div>
                    </div>
                    <div class="col-xl-6">
                      <div class="form-group">
                        <label>Lastname</label>
                        <input type="text" class="form-control" formControlName="lastName"
                          placeholder="Lastname">
                        <span
                          *ngIf="mainMember.get('lastName').touched && mainMember.get('lastName').errors?.required"
                          class="help-block text-danger">
                          <p>Lastname field is required.</p>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-6">
                      <div class="form-group">
                        <label>ID Number</label>
                        <input type="text" class="form-control" formControlName="idNumber" (ngModelChange)="validateIdNumber($event, 'M')"
                          placeholder="ID Number">
                        <span
                          *ngIf="mainMember.get('idNumber').touched && mainMember.get('idNumber').errors?.required"
                          class="help-block text-danger">
                          <p>ID Number field is required.</p>
                        </span>
                        <span
                          *ngIf="!mainMemberIsIdValid"
                          class="help-block text-danger">
                          <p>ID Number is not valid.</p>
                        </span>
                      </div>
                    </div>
                    <div class="col-xl-6">
                      <div class="form-group">
                        <label>Passport Number</label>
                        <input type="text" class="form-control"
                          formControlName="passportNumber" placeholder="Passport Number">
                        <span
                          *ngIf="mainMember.get('passportNumber').touched && mainMember.get('passportNumber').errors?.required && isSip"
                          class="help-block text-danger">
                          <p>Passport Number field is required.</p>
                        </span>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-xl-6">
                      <div class="form-group">
                        <label>Gender</label>
                        <select class="form-control" formControlName="gender">
                          <option [ngValue]="null" selected="true">--Select Gender--
                          </option>
                          <option value="MALE">MALE</option>
                          <option value="FEMALE">FEMALE</option>
                        </select>
                        <span
                          *ngIf="mainMember.get('gender').touched && mainMember.get('gender').errors?.required"
                          class="help-block text-danger">
                          <p>Gender field is required.</p>
                        </span>

                      </div>
                    </div>
                    <div class="col-xl-6">
                      <div class="form-group">
                        <label>Date Of Birth</label>
                        <input type="date" class="form-control"
                          formControlName="dateOfBirth" max="{{maxDate | date:'yyyy-MM-dd'}}" min="{{minDate | date:'yyyy-MM-dd'}}"  placeholder="Date Of Birth">
                        <span
                          *ngIf="mainMember.get('dateOfBirth').touched && mainMember.get('dateOfBirth').errors?.required"
                          class="help-block text-danger">
                          <p>Date of Birth field is required.</p>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-6">
                      <div class="form-group">
                        <label>Marital Status</label>
                        <select class="form-control" formControlName="maritalStatus">
                          <option [ngValue]="null" selected="true">--Select Marital
                            Status--</option>
                          <option value="MARRIED">MARRIED</option>
                          <option value="SINGLE">SINGLE</option>
                          <option value="DIVORCED">DIVORCED</option>
                          <option value="WIDOWER">WIDOWER</option>
                          <option value="WIDOW">WIDOW</option>
                        </select>
                        <span
                          *ngIf="mainMember.get('maritalStatus').touched && mainMember.get('maritalStatus').errors?.required"
                          class="help-block text-danger">
                          <p>Marital Status field is required.</p>
                        </span>
                      </div>
                    </div>
                    <div class="col-xl-6">
                      <div class="form-group">
                        <label>Nationality</label>
                        <input type="text" class="form-control"
                          formControlName="nationality" placeholder="Nationality">
                        <span
                          *ngIf="mainMember.get('nationality').touched && mainMember.get('nationality').errors?.required"
                          class="help-block text-danger">
                          <p>Nationality field is required.</p>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-6">
                      <div class="form-group">
                        <label>Mobile Number</label>
                        <input type="text" class="form-control"
                          formControlName="mobileNumber" placeholder="Mobile Number">
                        <span
                          *ngIf="mainMember.get('mobileNumber').touched && mainMember.get('mobileNumber').errors?.required"
                          class="help-block text-danger">
                          <p>Mobile Number field is required.</p>
                        </span>
                      </div>
                    </div>
                    <div class="col-xl-6">
                      <div class="form-group">
                        <label>Email</label>
                        <input type="text" class="form-control" formControlName="email"
                          placeholder="Email">
                        <span
                          *ngIf="mainMember.get('email').touched && mainMember.get('email').errors?.required"
                          class="help-block text-danger">
                          <p>Email field is required.</p>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-6">
                      <div class="form-group">
                        <label>Street</label>
                        <input type="text" class="form-control" formControlName="street"
                          placeholder="Street">
                        <span
                          *ngIf="mainMember.get('street').touched && mainMember.get('street').errors?.required"
                          class="help-block text-danger">
                          <p>Street field is required.</p>
                        </span>
                      </div>
                    </div>
                    <div class="col-xl-6">
                      <div class="form-group">
                        <label>City</label>
                        <input type="text" class="form-control" formControlName="city"
                          placeholder="City" value="Melbourne">
                        <span
                          *ngIf="mainMember.get('city').touched && mainMember.get('city').errors?.required"
                          class="help-block text-danger">
                          <p>City field is required.</p>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-6">
                      <div class="form-group">
                        <label>Province/State</label>
                        <input type="text" class="form-control" formControlName="province"
                          placeholder="Province/State">
                        <span
                          *ngIf="mainMember.get('province').touched && mainMember.get('province').errors?.required"
                          class="help-block text-danger">
                          <p>Province field is required.</p>
                        </span>
                      </div>
                    </div>
                    <div class="col-xl-6">
                      <div class="form-group">
                        <label>Country</label>
                        <input type="text" class="form-control" formControlName="country"
                          placeholder="Country" readonly>
                      </div>
                    </div>
                  </div>

                </div>

                <div class="step-btns">
                  <!-- <button mat-button matStepperPrevious>Back</button>&nbsp; -->
                  <button mat-raised-button color="primary" matStepperNext>Next</button>
                </div>

              </mat-step>

                <mat-step formGroupName="spouse" [stepControl]="spouse">
                  <ng-template matStepLabel>SPOUSE</ng-template>
                  <div>
                    <div class="outerDiv" *ngIf="quoteSummary?.mainMemberProduct?.productTypeName === 'INDIVIDUAL'">
                      <h4 class="text-center">Do you have a spouse?</h4>
                      <div style="margin-top: 20px;" class="confirm-btns">
                        <button (click)="haveSpouse(true)"  id="kt_login_signup_cancel"  class="btn btn-primary btn-elevate kt-login__btn-secondary confirm-btn">Yes</button>
                        <button (click)="haveSpouse(false)" ngbPopover="Please click next step button" triggers="mouseenter:mouseleave" popoverTitle="No Spouse"  id="kt_login_signup_cancel"  class="btn btn-secondary btn-elevate kt-login__btn-secondary confirm-btn">No</button>
                      </div>
                    </div>
                    <div *ngIf="quoteSummary?.mainMemberProduct?.productTypeName === 'FAMILY' || hasSpouse">

                        <div class="row">
                          <div class="col-xl-6">
                            <div class="form-group">
                              <label>Firstname</label>
                              <input type="text" class="form-control"
                                formControlName="firstName" placeholder="Firstname">
                                <span *ngIf="spouse.get('firstName').touched && spouse.get('firstName').errors?.required"
                                class="help-block text-danger">
                                <p>Firstname field is required.</p>
                              </span>

                            </div>
                          </div>
                          <div class="col-xl-6">
                            <div class="form-group">
                              <label>Lastname</label>
                              <input type="text" class="form-control"
                                formControlName="lastName" placeholder="Lastname">
                                <span *ngIf="spouse.get('lastName').touched && spouse.get('lastName').errors?.required"
                                class="help-block text-danger">
                                <p>Lastname field is required.</p>
                              </span>

                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-xl-6">
                            <div class="form-group">
                              <label>Gender</label>
                              <select class="form-control" formControlName="gender">
                                <option [ngValue]="null" selected="true">--Select Gender--
                                </option>
                                <option value="MALE">MALE</option>
                                <option value="FEMALE">FEMALE</option>
                              </select>
                              <span *ngIf="spouse.get('gender').touched && spouse.get('gender').errors?.required"
                              class="help-block text-danger">
                              <p>Gender field is required.</p>
                            </span>
                            </div>
                          </div>
                          <div class="col-xl-6">
                            <div class="form-group">
                              <label>Date Of Birth</label>
                              <input type="date" class="form-control"
                                formControlName="dateOfBirth" placeholder="Date Of Birth">
                                <span
                                *ngIf="spouse.get('dateOfBirth').touched && spouse.get('dateOfBirth').errors?.required"
                                class="help-block text-danger">
                                <p>Date of Birth field is required.</p>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-xl-6">
                            <div class="form-group">
                              <label>ID Number</label>
                              <input type="text" class="form-control"
                                formControlName="idNumber" placeholder="ID Number" (ngModelChange)="validateIdNumber($event, 'S')">
                                <span *ngIf="spouse.get('idNumber').touched && spouse.get('idNumber').errors?.required"
                              class="help-block text-danger">
                              <p>ID Number field is required.</p>
                            </span>
                            <span
                            *ngIf="!isSpouseIdValid"
                            class="help-block text-danger">
                            <p>ID Number is not valid.</p>
                          </span>

                            </div>
                          </div>
                          <div class="col-xl-6">
                            <div class="form-group">
                              <label>Passport Number</label>
                              <input type="text" class="form-control"
                                formControlName="passportNumber"
                                placeholder="Passport Number">

                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-xl-6">
                            <div class="form-group">
                              <label>Mobile Number</label>
                              <input type="text" class="form-control"
                                formControlName="mobileNumber" placeholder="Mobile Number">
                            </div>
                          </div>
                          <div class="col-xl-6">
                            <div class="form-group">
                              <label>Email</label>
                              <input type="text" class="form-control" formControlName="email"
                                placeholder="Email">

                            </div>
                          </div>
                        </div>

                    </div>
                  </div>
                  <div class="step-btns">
                    <button mat-button matStepperPrevious>Back</button>&nbsp;
                    <button mat-raised-button color="primary" matStepperNext>Next</button>
                  </div>
                </mat-step>
                <mat-step >
                  <ng-template matStepLabel>CHILDREN ({{childArray.length}})</ng-template>
                  <div class="align-right-flex">
                    <button  mat-raised-button color="primary" (click)="openDialog(false, 'C', undefined)">
                      <mat-icon>add</mat-icon>ADD
                    </button>
                  </div>

                  <ng-template #ChildrenRef>
										<table style="width: 100%;" class="table table-sm table-bordered">
											<thead>
												<tr>
													<th scope="col">#</th>
													<th scope="col">First</th>
													<th scope="col">Last</th>
													<th scope="col">Date of Birth</th>
													<th scope="col">Gender</th>
													<th scope="col">Relationship</th>
													<th scope="col">Action</th>
												</tr>
											</thead>
											<tbody *ngIf="childArray.length>0">
												<tr *ngFor="let item of childArray; let i = index">
													<th scope="row">{{i + 1}}</th>
													<td>{{item?.firstName}}</td>
													<td>{{item?.lastName}}</td>
													<td>{{item?.dateOfBirth}}</td>
													<td>{{item.gender}}</td>
													<td>{{item.relationship}}</td>
													<td>
														<button mat-icon-button color="primary"
															(click)="openDialog(true, 'C', item)">
															<mat-icon>create</mat-icon>
														</button>
														<button mat-icon-button color="warn"
															(click)="deleteChild(item.position)">
															<mat-icon>delete</mat-icon>
														</button>
													</td>
												</tr>


											</tbody>
										</table>
                 </ng-template>
                 <ng-template [ngTemplateOutlet]="ChildrenRef"></ng-template>
                  <div class="step-btns">
                    <button mat-button matStepperPrevious>Back</button>&nbsp;
                    <button mat-raised-button color="primary" matStepperNext>Next</button>
                  </div>
                </mat-step>
                <mat-step>
                  <ng-template matStepLabel>EXTENDED MEMBERS ({{extendedMemberArray.length}})</ng-template>
                  <div class="align-right-flex">
                    <button *ngIf="extendedMemberArray.length < dependentsCount && quoteSummary.subGroupName === 'SIXPACK'  else scorepack"
                    mat-raised-button color="primary"
                      (click)="openDialog(false, 'E', undefined)">
                      <mat-icon>add</mat-icon>ADD
                    </button>
                    <ng-template #scorepack>
                      <button *ngIf="quoteSummary.subGroupName === 'SCOREPACK' " mat-raised-button color="primary"
                        (click)="openDialog(false, 'E', undefined)">
                        <mat-icon>add</mat-icon>ADD
                      </button>
                    </ng-template>
                  </div>

                  <ng-template #ExtendedRef>
										<table style="width: 100%;" class="table table-sm table-bordered">
											<thead>
												<tr>
													<th scope="col">#</th>
													<th scope="col">First</th>
													<th scope="col">Last</th>
													<th scope="col">Date of Birth</th>
													<th scope="col">Gender</th>
													<th scope="col">Relationship</th>
													<th scope="col">Action</th>
												</tr>
											</thead>
											<tbody *ngIf="extendedMemberArray.length>0">
												<tr *ngFor="let item of extendedMemberArray; let i = index">
													<th scope="row">{{i + 1}}</th>
													<td>{{item?.firstName}}</td>
													<td>{{item?.lastName}}</td>
													<td>{{item?.dateOfBirth}}</td>
													<td>{{item.gender}}</td>
													<td>{{item.relationship}}</td>
													<td>
														<button mat-icon-button color="primary"
															(click)="openDialog(true, 'E', item)">
															<mat-icon>create</mat-icon>
														</button>
														<button mat-icon-button color="warn"
															(click)="deleteExtendedMember(item.position)">
															<mat-icon>delete</mat-icon>
														</button>
													</td>
												</tr>


											</tbody>
										</table>
                  </ng-template>
                  <ng-template [ngTemplateOutlet]="ExtendedRef"></ng-template>
                  <div class="step-btns">
                    <button mat-button matStepperPrevious>Back</button>&nbsp;
                    <button mat-raised-button color="primary" matStepperNext>Next</button>
                  </div>
                </mat-step>
                <mat-step>
                  <ng-template matStepLabel>BENEFICIARIES ({{beneficiaryArray.length}})</ng-template>
                  <div class="align-right-flex">
                    <button mat-raised-button color="primary" (click)="openDialog(false, 'B', undefined)">
                      <mat-icon>add</mat-icon>ADD
                    </button>
                  </div>

                  <!-- <ngb-alert *ngIf="beneficiaryArray.length === 0" style="margin: 10px;" type="warning" [dismissible]="true">Please add a beneficiary</ngb-alert> -->
                  <div >
                    <ng-template #BeneficiaryRef>
										<table style="width: 100%;" class="table table-sm table-bordered">
											<thead>
												<tr>
													<th scope="col">#</th>
													<th scope="col">First</th>
													<th scope="col">Last</th>
													<th scope="col">Date of Birth</th>
													<th scope="col">Gender</th>
													<th scope="col">Relationship</th>
													<th scope="col">Action</th>
												</tr>
											</thead>
											<tbody *ngIf="beneficiaryArray.length>0">
												<tr *ngFor="let item of beneficiaryArray; let i = index">
													<th scope="row">{{i + 1}}</th>
													<td>{{item?.firstName}}</td>
													<td>{{item?.lastName}}</td>
													<td>{{item?.dateOfBirth}}</td>
													<td>{{item.gender}}</td>
													<td>{{item.relationship}}</td>
													<td>
														<button mat-icon-button color="primary"
															(click)="openDialog(true, 'B', item)">
															<mat-icon>create</mat-icon>
														</button>
														<button mat-icon-button color="warn"
															(click)="deleteBeneficiary(item.position)">
															<mat-icon>delete</mat-icon>
														</button>
													</td>
												</tr>


											</tbody>
										</table>
                    </ng-template>
                    <ng-template [ngTemplateOutlet]="BeneficiaryRef"></ng-template>
                  </div>
                  <div class="step-btns">
                    <button mat-button matStepperPrevious>Back</button>&nbsp;
                    <button mat-raised-button color="primary" matStepperNext>Next</button>
                  </div>
                </mat-step>
                <!-- <mat-step>
                  <ng-template matStepLabel>PAYMENT</ng-template>
                  <div class="row">
                    <div class="col-xl-6">
                      <div class="form-group">
                        <label>Payment Method</label>
                        <select class="form-control" formControlName="paymentMethod">
                          <option [ngValue]="null" selected="true">Choose Payment Method</option>
                          <option value="CASH">CASH</option>
                          <option value="Ecocash">Ecocash</option>
                          <option value="One Money">One Money</option>
                          <option value="Debit Order">Debit Order</option>

                          <option value="Stop Order">Stop Order</option>
                        </select>


                      </div>
                    </div>

                  </div>

										<div *ngIf="form.get('paymentMethod').value == 'Stop Order' || form.get('paymentMethod').value == 'Debit Order'"  class="kt-wizard-v2__form">
											<div formGroupName="bankDetail"  class="kt-wizard-v2__form">
												<div class="row">
													<div class="col-xl-6">
														<div class="form-group">
															<label>Bank Name</label>
															<select class="form-control" formControlName="bank">
																<option [ngValue]="null" selected="true">Choose Bank</option>
																<option [ngValue]="item" *ngFor="let item of banks">{{item?.bankName}}</option>

															</select>

														</div>

													</div>
													<div class="col-xl-6">
														<div class="form-group">
															<label>Account Name</label>
															<input type="text" class="form-control"
																formControlName="accountName" placeholder="Account Name">

														</div>
													</div>
												</div>
												<div class="row">

													<div class="col-xl-6">
														<div class="form-group">
															<label>Account Number</label>
															<input type="text" class="form-control"
																formControlName="accountNumber" placeholder="Account Number">

														</div>
													</div>
													<div class="col-xl-6">
														<div class="form-group">
															<label>Type Of Account</label>
															<select class="form-control" formControlName="typeOfAccount">
																<option [ngValue]="null" selected="true">Choose Type Of Account</option>
																<option value="Cheque" >Cheque</option>
																<option value="Savings" >Savings</option>
																<option value="Transmission" >Transmission</option>

															</select>

														</div>

													</div>
												</div>
												<div class="row">

													<div class="col-xl-6">
														<div class="form-group">
															<label>Branch Code</label>
															<input type="number" class="form-control"
																formControlName="branchCode" placeholder="Branch Code">

														</div>
													</div>
													<div class="col-xl-6">
														<div class="form-group">
															<label>Debit Day</label>
															<select class="form-control" formControlName="debitDay">
																<option [ngValue]="null" selected="true">Choose Debit Day</option>
																<option [ngValue]="day" *ngFor="let day of days">{{day}}</option>


															</select>

														</div>

													</div>
												</div>
												<div class="row">

													<div class="col-xl-12">
														<div class="form-group">
															<label>Debit Note</label>
															<input type="text" class="form-control"
																formControlName="debitNote" placeholder="Debit Note">

														</div>
													</div>

												</div>
											</div>
                    </div>
                    <div class="step-btns">
                      <button mat-button matStepperPrevious>Back</button>&nbsp;
                      <button mat-raised-button color="primary" matStepperNext>Next</button>
                    </div>
                </mat-step> -->
                <!-- <mat-step>
                  <ng-template matStepLabel>Signature</ng-template>
                  <div  class="kt-wizard-v2__form">
										<h5>Write your signature here</h5>
										<div class="sig-pad">
                    <canvas #myCanvas id="signature-pad" class="signature-pad" width=100% height=300></canvas>
								     	</div>

										<div style="margin-top: 10px;" class="text-center">
										<button (click)="clearSignature()" type="button" class="btn btn-outline-warning btn-sm" >Clear</button>&nbsp;
									   </div>
                  </div>
                  <div class="step-btns">
                    <button mat-button matStepperPrevious>Back</button>&nbsp;
                    <button mat-raised-button color="primary" matStepperNext>Next</button>
                  </div>
                  </mat-step> -->
                  <mat-step>
                    <ng-template matStepLabel>CONFIRM DETAILS</ng-template>


                    <div class="mat-h5 text-center">CHILD DETAILS</div>
                    <ng-template [ngTemplateOutlet]="ChildrenRef"></ng-template>
                    <div class="mat-h5 text-center">EXTENDED DETAILS</div>
                    <ng-template [ngTemplateOutlet]="ExtendedRef"></ng-template>
                    <div class="mat-h5 text-center">BENEFICIARY DETAILS</div>
                    <ng-template [ngTemplateOutlet]="BeneficiaryRef"></ng-template>


                    <h3 style="text-align: center;">Your monthly premium is {{quoteSummary?.currencyCode}} {{actualPremium | number:'.2-2'}}. Press create policy to continue. After your policy is created you will be redirected to Paynow to make payment.</h3>
                    <div *ngIf="policyCreated" style="text-align: center;">Policy created successfull. <button mat-raised-button color="primary" (click)="onNavigate()">Make Payment</button></div>
                    <div class="step-btns">
                      <button mat-button matStepperPrevious>Back</button>&nbsp;
                      <!-- <button mat-raised-button color="primary" (click)="onSubmit()">Make Payment</button> -->
                      <button *ngIf="!policyCreated" (click)="onSubmit()" [disabled]="loading" class="btn btn-primary btn-elevate  confirm-btn"> CREATE POLICY <i *ngIf="loading" class="fa fa-spinner fa-spin"></i></button>
                    </div>
                    </mat-step>

                </mat-horizontal-stepper>


						</form>
						<!--end: Form Wizard Form-->


				<!--end: Wizard -->
			</div>
			<ng-template #popContentMainMember>
				<img *ngIf="quoteSummary?.mainMemberProduct?.planTypeName=== 'DIAMOND'" width="200px" height="auto" src="./assets/media/caskets/diamond.jpeg">
				<img *ngIf="quoteSummary?.mainMemberProduct?.planTypeName=== 'PLATINUM'" width="200px" height="auto" src="./assets/media/caskets/platinum.jpeg">
				<img *ngIf="quoteSummary?.mainMemberProduct?.planTypeName === 'GOLD'" width="200px" height="auto" src="./assets/media/caskets/gold.jpeg">
			</ng-template>
			<ng-template #popContentExtendedMember>
				<img *ngIf="quoteSummary?.extendedMemberProduct?.planTypeName === 'DIAMOND'" width="200px" height="auto" src="./assets/media/caskets/diamond.jpeg">
				<img *ngIf="quoteSummary?.extendedMemberProduct?.planTypeName === 'PLATINUM'" width="200px" height="auto" src="./assets/media/caskets/platinum.jpeg">
				<img *ngIf="quoteSummary?.extendedMemberProduct?.planTypeName === 'GOLD'" width="200px" height="auto" src="./assets/media/caskets/gold.jpeg">
			</ng-template>
			<div class="col-md-3">

				<h4 class="text-center">{{quoteSummary.mainMemberProduct?.name}}</h4>
				<h5 class="text-center">{{quoteSummary?.currencyCode}} {{quoteSummary?.r45636250 | number:'.2-2' }} quote premium per/month</h5>
        <h5 class="text-center">{{quoteSummary?.currencyCode}} {{actualPremium | number:'.2-2' }} actual premium per/month</h5>
				<hr>
				<h6 class="text-center">MAIN MEMBER</h6>

				<table style="width: 100%;" class="table table-hover  table-striped">
					<tbody>
						<tr *ngIf="quoteSummary?.mainMemberProduct !== undefined">
							<th>Casket</th>

							<td class="blinking" [ngbPopover]="popContentMainMember" triggers="mouseenter:mouseleave" popoverTitle="{{quoteSummary?.mainMemberProduct?.casketName}}">{{quoteSummary?.mainMemberProduct?.casketName}}</td>
						</tr>
						<tr *ngIf="quoteSummary?.mainMemberProduct !== undefined">
							<th>Grocery</th>

							<td>{{quoteSummary?.mainMemberProduct?.groceryAllowance}}</td>
						</tr>
						<tr >
							<th>Static Benefits </th>

							<td> Body removal, Wash and dress, Hearse </td>
						</tr>
						<tr>
							<th>Plan - {{quoteSummary?.mainMemberProduct?.planType}}</th>

							<th>{{quoteSummary?.currencyCode}} {{quoteSummary.mainMemberProduct?.premium}} </th>
						</tr>
					</tbody>
				</table>
				<hr>
				<h6 *ngIf="quoteSummary?.mainMemberBenefits.length > 0" class="text-center">MAIN MEMBER BENEFITS</h6>
				<table *ngIf="quoteSummary?.mainMemberBenefits.length > 0" style="width: 100%;" class="table table-sm">
					<tbody>
						<tr *ngFor="let item of quoteSummary?.mainMemberBenefits">
							<td>{{item.name}}</td>
							<td>{{quoteSummary?.currencyCode}} {{item.premium | number:'.2-2' }} </td>
						</tr>
						<tr>
							<th>Total</th>
							<th>{{quoteSummary?.currencyCode}} {{quoteSummary?.b45664925 | number:'.2-2'}}</th>
						</tr>
					</tbody>
				</table>


				<h6 class="text-center"> DEPENDENTS</h6>
				<table style="width: 100%;" class="table table-hover  table-striped">
					<thead>
						<tr>
							<th scope="col">Plan Type</th>
							<th scope="col">No. Of Dependents</th>
							<th scope="col">Premium</th>

						</tr>
					</thead>
					<tbody>
						<tr>
							<td>{{quoteSummary?.extendedMemberProduct?.planType}}</td>
							<td>{{extendedMemberArray.length + (hasSpouse ? 1 : 0)}} </td>
							<th>{{quoteSummary?.currencyCode}} {{quoteSummary?.t45663625 | number:'.2-2'}}</th>
						</tr>


					</tbody>
				</table>

				<hr>
				<h6 *ngIf="quoteSummary?.extendedMemberBenefits.length > 0" class="text-center">Extended Benefits - {{quoteSummary?.currencyCode}}
					{{quoteSummary?.c4566446 | number:'.2-2' }}</h6>
				<table *ngIf="quoteSummary?.extendedMemberBenefits.length > 0" style="width: 100%;"
					class="table table-sm">
					<tbody>
						<tr *ngFor="let item of quoteSummary?.extendedMemberBenefits">
							<td>{{item.name}}</td>
							<td>{{quoteSummary?.currencyCode}} {{item?.premium | number:'.2-2' }} * {{extendedMemberArray.length + (hasSpouse ? 1 : 0)}} </td>
						</tr>
						<tr>
							<th>Total</th>
							<th>{{quoteSummary?.currencyCode}} {{quoteSummary?.c4566446 | number:'.2-2'}}</th>
						</tr>
					</tbody>
				</table>
			</div>

		</div>



  </mat-card>
  <ng-template #content let-modal>
    <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }" ></ngx-loading>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">{{paymentMethodSelected}} PAYMENT STATUS - TRANSACTION PROCESSING</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Close')">
      <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <h5 style="color: red;">You will be charged extra fees.</h5>
     <h6>Please check your phone for a <strong>{{paymentMethodSelected}}</strong> transaction which is pending.
        The mobile number for this transaction is <strong>{{paymentMobileNumber}}</strong>. Enter your pin to confirm.</h6>
     <!-- <div class="kt-login__divider">
      <div class="kt-divider">
        <span></span>
        <span>OR</span>
        <span></span>
      </div>
    </div> -->
     <h6>{{message}}</h6>
    </div>
    <div class="text-center modal-footer">
      <!-- <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Confirm after entering your Pin on your phone.</button> -->
    </div>
    </ng-template>
  </app-layout>


