
<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }" ></ngx-loading>
<div class="container mt-5">
  <div class="row justify-content-center">
    <div class="col-md-6">
      <mat-card class="payment-card">
        <mat-card-header class="header">
        <h5>Nyaradzo Visa/Mastercard Payment</h5>
        </mat-card-header>
        <mat-card-content class="content">
          <div class="flex-container">
            <div class="item-img">
              <img src="./assets/media/logos/logo-9.png" alt="Visa logo" class="logo">
            </div>
            <div class="item-img">
              <img src="./assets/media/payments/visa-mastercard.png" alt="Mastercard logo" class="logo">
            </div>
          </div>
          <p class="amount">{{ amount | currency: currency }}</p>
          <p class="description">{{ description }}</p>
          <p class="reference">{{ reference }}</p>
        </mat-card-content>
        <mat-card-actions class="actions">
          <!-- <button mat-raised-button [disabled]="loading" color="primary" (click)="proceedToPayment()">Proceed to Payment</button> -->
          <button [disabled]="loading" color="primary" (click)="proceedToPayment()" mat-raised-button class="pr-button">
            PROCEED TO PAYMENT
          </button>
        </mat-card-actions>
      </mat-card>
    </div>
  </div>
</div>














