<mat-list>
  <mat-list-item routerLink="{{routes.DASHBOARD}}" routerLinkActive="active">
    <mat-icon>home</mat-icon> Dashboard
  </mat-list-item>


</mat-list>
<mat-list>
<mat-list-item routerLink="/policy/other" routerLinkActive="active">
  <mat-icon>supervisor_account</mat-icon> My Policy
</mat-list-item>
</mat-list>
<p class="sidebar-title">Payments</p>
  <mat-list>
    <mat-list-item  routerLinkActive="active"  routerLink="/payments/new">
      <mat-icon>monetization_on</mat-icon> Make Payment
    </mat-list-item>
    <mat-list-item routerLinkActive="active" routerLink="/payments/history">
      <mat-icon>request_page</mat-icon> Payment History
    </mat-list-item>
  </mat-list>
  <p class="sidebar-title">Get Cover</p>
  <mat-list>
    <mat-list-item  routerLinkActive="active"  routerLink="/get_covered/local/sp/SCOREPACK">
      <mat-icon>sports_soccer</mat-icon> Scorepack Plan
    </mat-list-item>
    <mat-list-item routerLinkActive="active" routerLink="/get_covered/local/SIXPACK">
      <mat-icon>supervised_user_circle</mat-icon> Sixpack Plan
    </mat-list-item>
    <mat-list-item routerLinkActive="active" routerLink="/get_covered/download_forms">
      <mat-icon>move_to_inbox</mat-icon> Download forms
    </mat-list-item>
  </mat-list>
<p class="sidebar-title">Help</p>

<mat-list>
  <!-- <mat-list-item>
    <mat-icon>library_books</mat-icon> Library
  </mat-list-item>
  <mat-list-item>
    <mat-icon>question_answer</mat-icon> Support
  </mat-list-item> -->
  <mat-list-item>
    <mat-list-item routerLinkActive="active" routerLink="/general/faqs">
    <mat-icon>help_outline</mat-icon> FAQS
  </mat-list-item>
</mat-list-item>
</mat-list>

<!-- <p class="sidebar-title">Projects</p>

<mat-list>
  <mat-list-item> <div class="project-circle project-circle_yellow"></div> My resend </mat-list-item>
  <mat-list-item> <div class="project-circle project-circle_blue"></div> Starred</mat-list-item>
  <mat-list-item> <div class="project-circle project-circle_pink"></div> Background </mat-list-item>
</mat-list> -->

