import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { DownloadFormDialogComponent } from '../download-form-dialog/download-form-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'kt-get-quote',
  templateUrl: './get-quote.component.html',
  styleUrls: ['./get-quote.component.scss']
})
export class GetQuoteComponent implements OnInit {
	@Input() links;
  constructor(private router: Router, public dialog: MatDialog) { }

  ngOnInit() {
  }
  navigate(url) {
	this.router.navigateByUrl(url);
   }
   downLoadForm(type): void {
	   let downloadData = undefined;
	   if(type === 'scorepack'){
		   downloadData = {link: './assets/media/pdfs/Score-Pack-Plan01.pdf', title: 'SCORE PACK FORM'}
	   } else if(type === 'sixpack'){
		   downloadData = {link: './assets/media/pdfs/Six-Pack-Plan01.pdf', title: 'SIX PACK FORM'}
	   }

	const dialogRef = this.dialog.open(DownloadFormDialogComponent, {
		data: downloadData
	   });

	dialogRef.afterClosed().subscribe(result => {
		 console.log('The dialog was closed');

	   });
	 }

}
