import { Component } from '@angular/core';
import { Observable } from 'rxjs';

import { DashboardService } from '../../services';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';


@Component({
  selector: 'app-dashboard-page',
  templateUrl: './dashboard-page.component.html',
  styleUrls: ['./dashboard-page.component.scss']
})
export class DashboardPageComponent {

  links = {sip: '/get_covered/sip', scorepack: '/get_covered/local/sp/SCOREPACK', sixpack: '/get_covered/local/SIXPACK'};
  constructor( private router: Router, private titleService:Title) {
    this.titleService.setTitle("Nyaradzo Self Service | Dashboard");
  }

  navigate(link) {
		this.router.navigate([link]);
	}
}
