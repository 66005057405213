
<app-header-auth></app-header-auth>

<!-- <div class="auth-page">
  <div class="box">
    <mat-card  class="mat-card-box">
      <router-outlet></router-outlet>
    </mat-card>
  </div>
</div> -->
<!--  -->
<div class="auth-page">
  <div class="box">
    <div class="inner">
      <mat-card >
        <router-outlet></router-outlet>
      </mat-card>
    </div>
  </div>
</div>


