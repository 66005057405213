import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';


const USER_KEY = 'USER_KEY';
const ROLE_KEY = 'RoleData';

@Injectable()
export class AuthStorage {
  constructor() {}

  logOut() {
    localStorage.removeItem(environment.authTokenKey);
    localStorage.removeItem(USER_KEY);
    localStorage.removeItem(ROLE_KEY);
    localStorage.clear();
  }
  public saveToken(token: string) {
    localStorage.removeItem(environment.authTokenKey);
    localStorage.setItem(environment.authTokenKey, token);
  }
  public saveRoles(roles: string) {
    localStorage.removeItem(ROLE_KEY);
    localStorage.setItem(ROLE_KEY, roles);
  }
  public saveUser(user: any) {
    localStorage.removeItem(USER_KEY);
    localStorage.setItem(USER_KEY, user);
  }

  public getToken(): string {
    return localStorage.getItem(environment.authTokenKey);
  }

  public getUser(): any {
	const user = JSON.parse(localStorage.getItem(USER_KEY));
	return user;
  }
  public getRoles(): any {
    return localStorage.getItem(ROLE_KEY);
  }
  public allRoles(): Array<string> {
    const r = JSON.parse(this.getRoles());
    return r;
  }
  public isLoggedIn(): boolean {
	  const token = this.getToken();
	  const user = this.getUser();
	  if (token) {
         if (token.length > 20 && user) {
             return true;
		 }
	  }
	  return false;
  }
}
