import { Component, EventEmitter, OnInit, Output, ChangeDetectorRef } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { finalize, takeUntil, tap } from 'rxjs/operators';
import { AuthService } from 'src/app/core/auth.service';
import { AuthStorage } from 'src/app/core/auth.storage';
import { ConfirmPasswordValidator } from 'src/app/core/confirm-password.validator';
import { CrudService } from 'src/app/core/crud.service';
import { Alert } from 'src/app/core/model/alert';
import { JwtTokenResponse } from 'src/app/core/model/token.response';

@Component({
  selector: 'app-sign-form',
  templateUrl: './sign-form.component.html',
  styleUrls: ['./sign-form.component.scss']
})
export class SignFormComponent implements OnInit {
  registerForm: FormGroup;
	loading = false;
	errors: any = [];
	policy: any;
	ngxLoading = false;
	viewMsg = false;
  exists = false;
  alert: Alert;
  private unsubscribe: Subject<any>;

	/**
	 * Component constructor
	 *
	 * @param router: Router
	 * @param auth: AuthService
	 * @param store: Store<AppState>
	 * @param fb: FormBuilder
	 * @param cdr
	 */
	constructor(
		private router: Router,
		private auth: AuthService,
		private fb: FormBuilder,
		private cdr: ChangeDetectorRef,
		private service: CrudService,
    private authStorage: AuthStorage,
    private toastService: ToastrService
	) {
    this.unsubscribe = new Subject();
	}

	/*
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
    */

	/**
	 * On init
	 */
	ngOnInit() {
		this.initRegisterForm();
	}

/*
    * On destroy
    */
   ngOnDestroy(): void {
		this.unsubscribe.next();
		this.unsubscribe.complete();
		this.loading = false;
	}
	/**
	 * Form initalization
	 * Default params, validators
	 */
	initRegisterForm() {
		this.registerForm = this.fb.group({
			id: [null],
			firstName: ['', [Validators.maxLength(25),
				Validators.minLength(2),
				//Validators.pattern('^(?=.*[a-zA-Z])[a-zA-Z0-9]+$'),
				Validators.required]],
			lastName: ['', [Validators.maxLength(25),
				Validators.minLength(2),
				//Validators.pattern('^(?=.*[a-zA-Z])[a-zA-Z0-9]+$'),
				Validators.required]],
			email: ['',
			Validators.compose([
				Validators.required,
				Validators.email,
				Validators.minLength(3),
				Validators.maxLength(100)
			]),
		],
			mobileNumber: ['',
			Validators.compose([
				Validators.required,
				Validators.minLength(7),
				Validators.maxLength(30)
			]),
		],
			password: ['', Validators.compose([
				Validators.required,
				Validators.minLength(6),
				Validators.maxLength(100)
			])
			],
			confirmPassword: ['', Validators.compose([
				Validators.required,
				Validators.minLength(6),
				Validators.maxLength(100)
			])
			],
			idNumber: [''],
			physicalAddress: [''],
			postalAddress: [''],
			gender: ['OTHER'],
			registrationApp: ['CLIENT_SERVICE_PORTAL'],
			maritalStatus: ['NOT_AVAILABLE'],
			title: ['NOT_AVAILABLE'],
			dateOfBirth: [''],
			passportNumber: [''],
			roles: [['ROLE_BASIC']],

		}, {
			validator: ConfirmPasswordValidator.MatchPassword
		});
	}

	/**
	 * Form Submit
	 */
	submitForm() {
		const controls = this.registerForm.controls;
		if (this.registerForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			return;
		}
		this.loading = true;
        this.saveClientDetails();
	}


	saveClientDetails() {
	     this.viewMsg = false;
       this.service.save(this.registerForm?.value, '/customer/save').subscribe(result => {
		   this.loading = false;
		   this.exists = result.duplicate;
		   if (result.duplicate) {

          this.toastService.error('Account already exists!', 'Account Exist');
          this.alert = {message: 'Account already exists!', type: 'danger'};
          this.viewMsg = true;
		   } else if (result) {
          this.toastService.success('Account created successfully!', 'Account Status');
          this.alert = {message: 'Account created successfully!', type: 'success'};
          this.toastService.info('Signing you in...', 'Account Created');
          this.viewMsg = true;
          this.authClient(result?.item.email, this.registerForm.get('password').value);
		   }
	   },
	   error => {
          this.loading = false;
          this.toastService.error('Error Encountered when creating your account!', 'danger');
          this.viewMsg = true;
	   },
	   () => {
          this.loading = false;
          this.cdr.markForCheck();
	      }
	   );
	}
	authClient(email: string, pwd: string) {
		this.ngxLoading = true;
		const authData = {userName: email, password: pwd};
		this.auth
		.login(authData.userName, authData.password)
		.pipe(
			tap((jwtTokenResponse: JwtTokenResponse) => {
				this.ngxLoading = false;
				if (jwtTokenResponse) {
					this.authStorage.saveToken( jwtTokenResponse.token);
					this.authStorage.saveUser(JSON.stringify(jwtTokenResponse.customer));
					this.router.navigate(['/dashboard']);
				} else {
          this.toastService.error('Error Encountered !', 'Error');
					this.viewMsg = true;
					this.router.navigateByUrl('/auth/login');
				}
			}),
			takeUntil(this.unsubscribe),
			finalize(() => {
				this.ngxLoading = false;
				// this.authNoticeService.setNotice(this.translate.instant('AUTH.VALIDATION.INVALID_LOGIN'), 'danger');
				this.cdr.markForCheck();
			})
		)
		.subscribe();
	}
	/**
	 * Checking control validation
	 *
	 * @param controlName: string => Equals to formControlName
	 * @param validationType: string => Equals to valitors name
	 */
	isControlHasError(controlName: string, validationType: string): boolean {
		const control = this.registerForm.controls[controlName];
		if (!control) {
			return false;
		}

		const result = control.hasError(validationType) && (control.dirty || control.touched);
		return result;
	}
  navigate(link) {
		this.router.navigate([link]);
	}
}
