<div class="footer">
  <div class="footer__link">
    <a class="footer__link-item" [href]="flatlogic">Nyaradzo 2023</a>
    <a class="footer__link-item" [href]="flatlogicAbout">About Us</a>
    <!-- <a class="footer__link-item" [href]="flatlogicBlog">Blog</a> -->
  </div>
  <div class="footer__icon">
    <button mat-mini-fab>
      <mat-icon fontSet="fa fa-fs" fontIcon="fa-facebook-square"></mat-icon>
    </button>
    <button mat-mini-fab>
      <mat-icon fontSet="fa fa-fs" fontIcon="fa-twitter-square"></mat-icon>
    </button>
    <!-- <button mat-mini-fab>
      <mat-icon fontSet="fa fa-fs" fontIcon="fa-github-square"></mat-icon>
    </button> -->
  </div>
</div>
