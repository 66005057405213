
 <p class="group-sub-title">Register For Payment</p>
 <ngb-alert *ngIf="alert" [type]="alert.type">{{ alert.message }}</ngb-alert>
<form [formGroup]="registerForm"  class="form"  action="https://zympayadvantage-staging.azurewebsites.net/home/post" #myFormPost  method="POST"
>
<!-- <form [formGroup]="registerForm"  class="form"  action="http://localhost:4759/home/post" #myFormPost  method="POST"
> -->
  <input name="setuptype" type="hidden" value="{{paymentType}}">
  <input name="isAgent" type="hidden" value="true">
  <input name="reference" type="hidden" value="{{policyNumber}}">
  <input name="clientId" type="hidden" value="86">
  <input name="productId" type="hidden" value="NYARADZOINS">
  <!-- <input name="countrycode" type="hidden" value="{{countryInfo?.iso2}}"> -->
  <input name="countrycode" type="hidden" value="GB">
  <input name="postcode" type="hidden" value="{{countryInfo?.dialCode}}">
  <input name="county" type="hidden" value="{{countryInfo?.name}}">
  <input name="title" type="hidden" value="{{registerForm?.value?.title}}">
  <input name="firstname" type="hidden" value="{{registerForm?.value?.firstname}}">
  <input name="surname" type="hidden" value="{{registerForm?.value?.surname}}">
  <input name="address1" type="hidden" value="{{registerForm?.value?.address1}}">
  <input name="address2" type="hidden" value="{{registerForm?.value?.address2}}">
  <input name="amount" type="hidden" value="{{registerForm?.value?.amount}}">
  <input name="email" type="hidden" value="{{registerForm?.value?.email}}">
  <input name="city" type="hidden" value="{{registerForm?.value?.city}}">
  <input name="currencycode" type="hidden" value="{{registerForm?.value?.currencycode}}">
  <!-- <input name="currencycode" type="hidden" value="GBP"> -->
  <input name="paymentmethod" type="hidden" value="{{registerForm?.value?.paymentmethod}}">
  <input name="frequency" type="hidden" value="{{registerForm?.value?.frequency}}">
  <input name="mobile" type="hidden" value="{{registerForm?.value?.mobile?.e164Number}}">
  <input name="telephone" type="hidden" value="{{registerForm?.value?.mobile?.e164Number}}">
  <h3>Personal Details</h3>
  <div class="row">
    <div class="col-md-2">
      <div class="form-group">

        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Title</mat-label>
          <mat-select placeholder="Title" formControlName="title">
            <mat-option value="MR">MR</mat-option>
            <mat-option value="MRS">MRS</mat-option>
            <mat-option value="MISS">MISS</mat-option>
          </mat-select>
          <mat-error *ngIf="isControlHasError('title','required')">
            <strong>Title is required</strong>
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="col-md-4">
      <div class="form-group">
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Firstname</mat-label>
          <input matInput type="text" placeholder="Firstname" formControlName="firstname" required/>
        </mat-form-field>
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Lastname</mat-label>
          <input matInput type="text" placeholder="Lastname" formControlName="surname"/>
          <mat-error *ngIf="isControlHasError('surname','required')">
            <strong>Lastname required</strong>
          </mat-error>
        </mat-form-field>
      </div>
    </div>

  </div>
  <div class="row">
    <div class="col-md-6">
      <div class="form-group">
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Address</mat-label>
          <textarea matInput  type="text" placeholder="Address" formControlName="address1"></textarea>
          <mat-error *ngIf="isControlHasError('address1','required')">
            <strong>Address required</strong>
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">

        <mat-label>Phone</mat-label><br>
        <ngx-intl-tel-input  [preferredCountries]="preferredCountries"  cssClass="form-control"
        [enableAutoCountrySelect]="true" [enablePlaceholder]="true" [searchCountryFlag]="true"
        [searchCountryField]="[SearchCountryField.Iso2]" [selectFirstCountry]="false"
        [selectedCountryISO]="CountryISO.Zimbabwe" [maxLength]="30"
        [phoneValidation]="false" [separateDialCode]="separateDialCode"
        [numberFormat]="PhoneNumberFormat.National" name="phoneNumber" formControlName="mobile" (countryChange)="mobileNumberChange($event)">
     </ngx-intl-tel-input>

      </div>

    </div>

  </div>
  <div class="row">
    <div class="col-md-6">
      <div class="form-group">
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>City</mat-label>
          <input matInput type="text" placeholder="City" formControlName="city"/>
          <mat-error *ngIf="isControlHasError('city','required')">
            <strong>City required</strong>
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Email</mat-label>
          <input matInput type="email" placeholder="Email" formControlName="email" required="true"/>
          <mat-error *ngIf="isControlHasError('email','required')">
            <strong>Email required</strong>
          </mat-error>
        </mat-form-field>
      </div>
    </div>



  </div>
  <div class="row">

  </div>



  <!-- <div class="row">
    <div class="col-md-12">
      <div class="form-group">
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Country</mat-label>
          <mat-select-country appearance="outline" formControlName="country"
                    label="Country"
                    [countries]="predefinedCountries"
                    (onCountrySelected)="onCountrySelected($event)">
           </mat-select-country>
        </mat-form-field>
      </div>
    </div>

  </div> -->

  <h3>Payment Details</h3>
  <div class="row">
    <div class="col-md-6">
      <div class="form-group">
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Currency Code</mat-label>
          <input matInput type="text" formControlName="currencycode" readonly="true"/>
        </mat-form-field>
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Amount</mat-label>
          <input matInput type="text" placeholder="Amount" formControlName="amount" required="true"/>
          <mat-error *ngIf="isControlHasError('amount','required')">
            <strong>Amount required</strong>
          </mat-error>
        </mat-form-field>
      </div>
    </div>

  </div>
  <div class="row">
    <div class="col-md-12">
      <div class="form-group">
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Frequency</mat-label>
          <input *ngIf="type === 'once-off'" matInput type="text" formControlName="frequency"/>
          <mat-select  *ngIf="type === 'recurring'"  placeholder="Frequency" formControlName="frequency">
            <!-- <mat-option *ngIf="type === 'once-off'" value="None">None</mat-option> -->
            <mat-option *ngIf="type === 'recurring'" value="Monthly">Monthly</mat-option>
            <mat-option *ngIf="type === 'recurring'" value="Quarterly">Quarterly</mat-option>
            <mat-option *ngIf="type === 'recurring'" value="Monthly">Half Yearly</mat-option>
            <mat-option *ngIf="type === 'recurring'" value="Yearly">Yearly</mat-option>
          </mat-select>
          <mat-error *ngIf="isControlHasError('frequency','required')">
            <strong>Frequency is required</strong>
          </mat-error>
        </mat-form-field>
      </div>
      </div>
      </div>
  <div class="form-actions">
    <button class="form-actions__create" mat-raised-button color="primary" [disabled]="!registerForm.valid" type="button" (click)="myFormPost.submit()">Submit <i
      *ngIf="loading" class="fa fa-spinner fa-spin"></i></button>
  </div>
</form>
<!-- {{registerForm?.value | json}} -->
